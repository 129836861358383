import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import Layout from '../components/global/layout'
import Listing from '../components/generic-listing/listing'
import Masthead from '../components/layout/masthead'

const ServiceIndex = ({ data, pageContext }) => {
  if (!data) 
    return null

  const document = data.prismicServiceIndex.data
  const services = []
  
  document.documents.map((doc) => {
    return (
      services.push(doc.service.document)
    )
  })

  return (
    <Layout pageTitle={document.meta_title.text || document.title.text} 
            metaTitle={document.meta_title.text} 
            metaDescription={document.meta_description.text || document.summary.text}
            shareImage={document.share_image.url}>
      <Masthead title={document.title.text}
                summary={document.summary.text} />
      <Listing articles={services} />
    </Layout>
  )
}

export const query = graphql`
  query ServiceIndexPageQuery {
    prismicServiceIndex {
      _previewable
        data {
          title {
            text
          }
          summary {
            text
          }
          meta_title {
            text
          }
          meta_description {
            text
          }
          share_image {
            url
          }
          documents {
            service {
              document {
                ... on PrismicService {
                  id
                  data {
                    title {
                      text
                    }
                    summary {
                      text
                    }
                    image {
                      alt
                      url
                    }
                  }
                  url
                }
              }
            }
          }
        }
    }
  }
`

export default withPrismicPreview(ServiceIndex, repositoryConfigs)